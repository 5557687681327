/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import DeviceWrapper from '@/components/AZCustomComponent/DeviceWrapper';
import { Grid } from '@/components/Grid';
import React from 'react';
import styles from './styles.module.scss';

type VideoContentBlockProps = {
  data: {
    desktop?: boolean;
    tablet?: boolean;
    mobile?: boolean;
    videoEmbedCode3?: string;
    videoSubTitle3?: string;
    videoSubText3?: string;
    '@type': string;
    name: string;
    videoEmbedCode2?: string;
    videoSubTitle2?: string;
    videoSubText2?: string;
    videoContentBlockTitle?: string;
    videoEmbedCode1?: string;
    videoSubTitle1?: string;
    videoSubText1?: string;
  };
};

export const VideoContentBlock = ({ data }: VideoContentBlockProps) => {
  return (
    <DeviceWrapper
      desktop={data?.desktop || false}
      tablet={data?.tablet || false}
      mobile={data?.mobile || false}
    >
      <div className={styles.contentBlockContainer}>
        {data.videoContentBlockTitle ? (
          <>
            <>
              <h2 className={styles.pageTitle}>{data.videoContentBlockTitle}</h2>
            </>
            <Grid item xs={12} className={styles.separatorItem}>
              <div className={`${styles.separator}`} />
            </Grid>
          </>
        ) : null}
        <Grid item xs={12} className={styles.videos}>
          <div className={styles.videoBlock}>
            {data.videoEmbedCode1 && (
              <div dangerouslySetInnerHTML={{ __html: data.videoEmbedCode1 }}></div>
            )}
            {data.videoSubTitle1 && <h3 className={styles.subTitle}>{data.videoSubTitle1}</h3>}
            {data.videoSubText1 && (
              <div
                className={styles.subText}
                dangerouslySetInnerHTML={{ __html: data.videoSubText1 }}
              ></div>
            )}
          </div>
          <div className={styles.videoBlock}>
            {data.videoEmbedCode2 && (
              <div dangerouslySetInnerHTML={{ __html: data.videoEmbedCode2 }}></div>
            )}
            {data.videoSubTitle2 && <h3 className={styles.subTitle}>{data.videoSubTitle2}</h3>}
            {data.videoSubText2 && (
              <div
                className={styles.subText}
                dangerouslySetInnerHTML={{ __html: data.videoSubText2 }}
              ></div>
            )}
          </div>
          <div className={styles.videoBlock}>
            {data.videoEmbedCode3 && (
              <div dangerouslySetInnerHTML={{ __html: data.videoEmbedCode3 }}></div>
            )}
            {data.videoSubTitle3 && <h3 className={styles.subTitle}>{data.videoSubTitle3}</h3>}
            {data.videoSubText3 && (
              <div
                className={styles.subText}
                dangerouslySetInnerHTML={{ __html: data.videoSubText3 }}
              ></div>
            )}
          </div>
        </Grid>
      </div>
    </DeviceWrapper>
  );
};
