/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import React from 'react';
import styles from './styles.module.scss';
import { Grid } from '@/components/Grid';
import cx from 'classnames';

type Props = {
  title: string;
  rootClass: string;
};

const AZTitleHeader = (props: Props) => (
  <div className={cx(styles.root, { [props?.rootClass]: props?.rootClass })}>
    <h2 className={styles.headerTitle}>{props.title}</h2>
    <Grid item xs={12} className={styles.separatorItem}>
      <div className={styles.separator} />
    </Grid>
  </div>
);

export default AZTitleHeader;
