/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import DeviceWrapper from '@/components/AZCustomComponent/DeviceWrapper';
import { Legal } from '@/components/AZCustomComponent/Legal';
import { Grid } from '@/components/Grid';
import styles from './styles.module.scss';
import cx from 'classnames';
import type { RichTextBlock } from '@/types/legacy';

type RichTextContentBlockProps = {
  data: Omit<RichTextBlock, 'contentType'>;
};

export const RichTextContentBlock = ({ data }: RichTextContentBlockProps) => {
  return (
    <DeviceWrapper
      desktop={data.desktop || false}
      tablet={data.tablet || false}
      mobile={data.mobile || false}
    >
      <div className={styles.richTextContentBlockContainer}>
        {data.titleText && (
          <h2
            className={cx(styles.titleText, {
              [styles[`${data.titleTextAlignment}`]]: data.titleTextAlignment,
            })}
          >
            {data.titleText}
          </h2>
        )}
        {data.titleText && data.titleTextUnderline && data.titleTextAlignment === 'left' && (
          <Grid item xs={12} className={styles.separatorItem}>
            <div className={`${styles.separator}`} />
          </Grid>
        )}

        <Grid item xs={12} className={styles.contentBlockContainer}>
          <div dangerouslySetInnerHTML={{ __html: data.content || '' }}></div>
        </Grid>
        <div
          className={cx({
            [styles[`${data.titleTextAlignment}`]]: data.titleTextAlignment,
          })}
        >
          <div
            className={cx({
              [styles[`${data.titleTextAlignment}`]]: data.titleTextAlignment,
            })}
          >
            <Legal content={data} />
          </div>
        </div>
      </div>
    </DeviceWrapper>
  );
};
