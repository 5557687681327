/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import CustomizedExpansionPanel from '@/components/AZCustomComponent/ExpansionPanel';
import ExpandIcon from '../../../../public/images/footer/expand_arrow.svg';
import { SmartLink as Link } from '../../../utils/smartLink';
import { IconButton } from '@/components/IconButton/IconButton';
import { rightArrowAZBlack } from '@/constants/images/rightArrowAZBlack';
import AZTitleHeader from '@/components/AZCustomComponent/AZTitleHeader';
import styles from './styles.module.scss';
import type { FAQsContentBlock } from '@/types/legacy';

type Props = {
  content: Omit<FAQsContentBlock, 'contentType'>;
  desktop?: boolean;
  tablet?: boolean;
  mobile?: boolean;
};

const FAQContentBlock = (data: Props) => {
  const { headerTitle, faqsContentBlock, ctaLinkText, ctaButtonText, ctaLink } = data?.content;

  return (
    <div className={styles.faqContentBlockContainer}>
      {headerTitle && <AZTitleHeader title={headerTitle} rootClass={styles.titleRoot} />}
      {faqsContentBlock && (
        <div className={styles.expansionContainer}>
          {faqsContentBlock.map((item, i) => {
            return (
              <CustomizedExpansionPanel
                classes={{
                  summary: styles.expansionSummary,
                }}
                key={i}
                title={item?.questionText}
                summary={<h3 className={styles.questionText}>{item?.questionText}</h3>}
                content={
                  <div className={styles.answerTextContainer} tabIndex={0}>
                    <div className={styles.answerText}>{item?.answerText}</div>
                  </div>
                }
                expandIcon={<ExpandIcon />}
              />
            );
          })}
        </div>
      )}
      {ctaLinkText && ctaLink && (
        <div className={styles.ctaLinkContainer}>
          <Link className={styles.ctaLinkStyle} to={ctaLink}>
            {ctaLinkText}
          </Link>
        </div>
      )}
      {ctaButtonText && ctaLink && (
        <Link className={styles.ctaButtonLink} to={ctaLink}>
          <div className={styles.ctaButtonConatiner}>
            <IconButton ariaLabel={ctaLinkText} className={styles.iconRoot}>
              <div className={styles.ctaButtonText}>{ctaButtonText}</div>
              <img src={rightArrowAZBlack.src} alt="" />
            </IconButton>
          </div>
        </Link>
      )}
    </div>
  );
};

export default FAQContentBlock;
